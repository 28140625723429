body {
  margin: 0;
  padding: 0;
  font-family: "Avenir Next","Helvetica Neue",Helvetica,Arial,sans-serif;
  background-color: #0072c7;
}

.Layout_Content__1ZCI9 {
    margin-top: 72px;
}
.Product_Product__2vNml {
    margin: auto;
    /*
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    */
    /*
    background-color: white;
    box-sizing: border-box;
    border-radius: 5px;
    */
    width: 80%;
    max-width: 650px;
    padding: 8px;
    padding-top: 50px;
    color: white;
    font-size: 18px;
    font-weight: normal;
    display: inline-flex;
}

.Product_ProductPanel__2l0Ov img {
    height: 200px;
}

.Product_ProductPanel__2l0Ov ul {
    text-align: center;
    list-style-position: inside;
}

.Product_ProductPanel__2l0Ov {
    max-width: 400px;
    margin: auto;
}

@media (max-width: 812px) {
    .Product_Product__2vNml {
        display: inline-block;
    }
}

.Content_Content__2Kj17 {
    width: 100%;
    /*
    margin: auto;
    height: 500px;
    */
    margin:auto;
    overflow: scroll;
    text-align: center;
    overflow: hidden;
    padding-bottom: 50px;
}

/* http://stephen.io/mediaqueries/#iPhone */
/* iphone 8 */
@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px) {
    .Content_Content__2Kj17 {
        width: 350px;
        /* height: 400px; */
    }
}

/* iPhone X */
@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (-webkit-device-pixel-ratio : 3) {
    .Content_Content__2Kj17 {
        width: 350px;
        /* height: 500px; */
    }
}

/*
@media (min-width: 500px) and (min-height: 400px) {
    .Content {
        width: 350px;
        height: 300px;
    }
}

@media (min-width: 500px) and (min-height: 401px) {
    .Content {
        width: 450px;
        height: 600px;
    }
}

@media (min-width: 1000px) and (min-height: 700px) {
    .Content {
        width: 700px;
        height: 800px;
    }
}
*/

.Logo_Logo__1UMks {
    background-color: #0043aa;
    padding: 1px;
    height: 100%;
    box-sizing: border-box;
    border-radius: 5px;
}

.Logo_Logo__1UMks img {
    height: 100%;
}

.Header_Header__1FLHe {
    height: 64px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #0043aa;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
}

.Header_Header__1FLHe nav {
    height: 100%;
}

.Header_Logo__KkMCM {
    height: 80%;
}

@media (max-width: 499px) {
    .Header_DesktopOnly__1bwod {
        display: none;
    }
}

.Footer_Footer__2KNFd {
    height: 56px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    color: white;
    background-color: #0072c7;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
}

.Footer_Footer__2KNFd nav {
    height: 100%;
}

.Footer_Logo__FDTuY {
    height: 80%;
}


@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px)
and (orientation : landscape) {
    .Footer_Footer__2KNFd {
        position: relative;
    }
}

/* iPhone X landscape */
@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (-webkit-device-pixel-ratio : 3)
and (orientation : landscape) {
    .Footer_Footer__2KNFd {
        position: relative;
    }
}

@media (max-height: 470px) {
    .Footer_Footer__2KNFd {
        position: relative;
    }
}

