.Product {
    margin: auto;
    /*
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    */
    /*
    background-color: white;
    box-sizing: border-box;
    border-radius: 5px;
    */
    width: 80%;
    max-width: 650px;
    padding: 8px;
    padding-top: 50px;
    color: white;
    font-size: 18px;
    font-weight: normal;
    display: inline-flex;
}

.ProductPanel img {
    height: 200px;
}

.ProductPanel ul {
    text-align: center;
    list-style-position: inside;
}

.ProductPanel {
    max-width: 400px;
    margin: auto;
}

@media (max-width: 812px) {
    .Product {
        display: inline-block;
    }
}
